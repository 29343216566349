<template>
  <fw-panel boxed="xs">
    <LoadingPlaceholder v-if="loading" list />
    <fw-panel-info v-else-if="!loading && !enrollments.length" empty>
      Ainda não existem certificados associados a esta edição.
    </fw-panel-info>

    <RecycleScroller v-else v-slot="{ item }" key-field="key" :items="enrollments" :item-size="48" :buffer="50">
      <fw-person
        size="sm"
        no-style
        :person="users?.[item?.user_key] ?? {}"
        :clickable="false"
        :checked="selectedUsers.includes(item.key)"
        :selectable="true"
        class="hover:bg-gray-200 hover:bg-opacity-50 rounded-lg overflow-hidden"
        @selected="$emit('selected', item.user_key, item.key, $event)"
      >
        <template #secondline>
          <div v-if="item.notified_at" class="text-xs text-gray-500">
            Notificação enviada em {{ item.notified_at | formatDateTime }}.
          </div>
          <div v-else class="text-xs text-gray-400">Notificação não enviada.</div>
        </template>
        <template #options>
          <RecordFileEntry
            :key="item.file?.key"
            type="nano"
            paddingless
            :can-edit="false"
            :can-remove="false"
            :allow-classified="false"
            :file="item.file"
            @download="downloadFile(item.file)"
          />
          <fw-menu-more append-to-body>
            <b-dropdown-item custom aria-role="menuitem" class="paddingless">
              <fw-button
                type="basic-action"
                size="sm"
                custom-class="w-full"
                label="Eliminar"
                @click.native="$emit('delete', item.key)"
              >
                Remover
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </template>
      </fw-person>
    </RecycleScroller>
  </fw-panel>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    LoadingPlaceholder,
    RecordFileEntry,
    RecycleScroller
  },

  props: {
    courseKey: {
      type: String,
      required: true
    },
    editionKey: {
      type: String,
      required: true
    },
    selectedUsers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isModalActive: false,
      activeEnrollment: null,
      loading: false,
      enrollments: [],
      users: {}
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    hasUsersToNotify() {
      return this.enrollments.some(el => !el.notified_at)
    }
  },

  mounted() {
    this.getEnrollments()
  },

  methods: {
    async getEnrollments() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getEnrollments(this.courseKey, this.editionKey)
        console.log('getEnrollments :>> ', response)
        this.enrollments = response.enrollments
        this.users = response.users
      })

      this.loading = false

      // Update parent component with the number of enrollments
      this.$emit('enrollments-count', this.enrollments.length)
    },

    deleteEnrollments(keys) {
      this.enrollments = this.enrollments.filter(el => !keys.includes(el.key))
      // Update parent component with the number of enrollments
      this.$emit('enrollments-count', this.enrollments.length)
    },

    updateEnrollment(key, data) {
      let index = this.enrollments.findIndex(el => el.key == key)
      if (index > -1) this.$set(this.enrollments, index, data)
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    }
  }
}
</script>
