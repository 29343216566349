<template>
  <div>
    <fw-panel :title="'Adicionar certificados'" :loading="loading" after-loading-checked>
      <div v-if="step == 'data'" class="mt-2 mb-5">
        <div>
          <fw-label>Ficheiros a adicionar</fw-label>
          <div class="mb-2">
            <div v-if="files && files.length > 0" class="files mb-1.5">
              <RecordFileEntry
                v-for="(file, f) in files"
                :key="file.key"
                :can-edit="true"
                :allow-classified="false"
                :file="file"
                @save="saveFile(f, $event)"
                @remove="removeFile(file.key)"
                @download="downloadFile(file)"
              />
            </div>

            <Uploader
              :is-docked="true"
              layout="minimal"
              allowed="pdf"
              :clear-after="true"
              :files.sync="filesToUpload"
              @upload="uploaded"
            />

            <fw-tip v-if="$v.files.$error" error>
              Insira os ficheiros
            </fw-tip>
          </div>
        </div>
      </div>

      <template v-if="step == 'confirm'">
        <div v-if="checking" class="mb-3">
          <div class="flex gap-3 items-center text-sm text-gray-500 font-medium">
            <div class="h-6 w-6">
              <fw-icon-loading v-if="checking" class="animate-spin w-6 h-6" />
            </div>
            <div>
              <div>A verificar ficheiros...</div>
            </div>
          </div>
        </div>
        <div v-if="!checking && !loading" class="flex flex-col gap-3">
          <div class="files">
            <fw-label :counter="count.found.length">Ficheiros prontos a adicionar</fw-label>
            <RecordFileEntry
              v-for="file in foundFiles"
              :key="file.key"
              :can-edit="false"
              :can-remove="false"
              show-as="invalid"
              :allow-classified="false"
              :file="file"
              @download="downloadFile(file)"
            >
              <template #secondline>
                <fw-tag v-if="count.duplicated.includes(file.key)" type="light-orange">Potencialmente duplicado</fw-tag>
              </template>
            </RecordFileEntry>
          </div>

          <div v-if="count.duplicated.length" class="files">
            <fw-label :counter="count.duplicated.length" color="text-yellow-600"
              >Ficheiros potencialmente duplicados</fw-label
            >
            <div class="text-xs text-gray-500 mb-2">
              Se avançar, estes ficheiros (potencialmente duplicados) serão adicionados e alguns utilizadores ficaram
              com mais do que um certificado associado na edição do curso.
            </div>
            <!-- <RecordFileEntry
              v-for="file in duplicatedFiles"
              :key="file.key"
              :can-edit="false"
              :can-remove="false"
              :allow-classified="false"
              :file="file"
              @download="downloadFile(file)"
            /> -->
          </div>

          <div v-if="notFoundFiles.length" class="files">
            <fw-label :counter="notFoundFiles.length" color="text-yellow-600"
              >Ficheiros sem utilizadores associados</fw-label
            >
            <div class="text-xs text-gray-500 mb-2">
              Se avançar, estes ficheiros não serão adicionados.
            </div>
            <RecordFileEntry
              v-for="file in notFoundFiles"
              :key="file.key"
              :can-edit="false"
              :can-remove="false"
              :allow-classified="false"
              :file="file"
              @download="downloadFile(file)"
            />
          </div>
        </div>
      </template>
    </fw-panel>

    <fw-panel-info debug label="Map data (raw)">
      <json-viewer :value="{ step, files: files, count, notFoundFiles, duplicatedFiles }"></json-viewer>
    </fw-panel-info>

    <div class="flex-1 flex justify-end mt-3 gap-5 items-center">
      <fw-button type="link-muted" :disabled="checking || loading" @click.native="close()">Cancelar</fw-button>
      <fw-button
        v-if="step == 'confirm' && !checking"
        type="link-muted"
        :disabled="checking || loading"
        @click.native="previousStep()"
      >
        Anterior
      </fw-button>
      <fw-button v-else-if="step == 'data'" type="link" wider @click.native="nextStep()">
        Seguinte
      </fw-button>
      <fw-button
        v-if="step == 'confirm'"
        type="primary"
        wider
        :disabled="checking || loading"
        :loading="checking || loading"
        @click.native="save"
      >
        Adicionar
      </fw-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    Uploader,
    RecordFileEntry
  },

  validations: {
    files: { required }
  },

  props: {
    courseKey: {
      type: String,
      required: true
    },
    editionKey: {
      type: String,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      files: [],
      step: 'data',
      count: {
        found: [],
        duplicated: [],
        found_users: [],
        duplicated_users: [],
        total: 0,
        users: {}
      },
      checking: false,
      filesToUpload: [],
      enrollments: [],
      users: {}
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    foundFiles() {
      if (!this.count?.found) return []
      return this.files.filter(f => this.count.found.includes(f.key))
    },

    notFoundFiles() {
      if (!this.count?.found) return []
      return this.files.filter(f => !this.count.found.includes(f.key))
    },

    duplicatedFiles() {
      if (!this.count?.duplicated) return []
      return this.files.filter(f => this.count.duplicated.includes(f.key))
    }
  },

  methods: {
    nextStep() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.step = 'confirm'
      this.getUsersCount()
    },

    previousStep() {
      this.step = 'data'
    },

    async getUsersCount() {
      this.checking = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addEnrollments(this.courseKey, this.editionKey, {
          files: this.files,
          check_only: true
        })
        console.log('check :>> ', response)
        this.count = response
      })
      setTimeout(() => {
        this.checking = false
      }, 750)
    },

    async saveEnrollments() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addEnrollments(this.courseKey, this.editionKey, {
          files: this.files,
          check_only: false
        })
        console.log('save data :>> ', response)
        this.$emit('added')
      })

      this.loading = false
    },

    close(check = true) {
      console.log('close modal')
      if (check && this.files?.length > 0) {
        this.$buefy.dialog.confirm({
          title: 'Cancelar criação certificados',
          message: `Tem a certeza que deseja cancelar a criação de certificados? Ao sair desta janela,
            irá perder todas as informações inseridas no forumlário e os ficheiros adicionados.`,
          confirmText: 'Sair da janela',
          cancelText: 'Cancelar',
          type: 'is-dark',
          onConfirm: () => {
            this.$v.$reset()
            this.$emit('close')
          }
        })
      } else {
        this.$v.$reset()
        this.$emit('close')
      }
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.count.found <= 0) {
        this.$buefy.dialog.alert({
          message: 'Não foram encontrados os utilizadores.',
          type: 'is-danger',
          confirmText: 'Ok'
        })
        return
      }

      let message = ''
      if (this.count.found.length != this.count.total) {
        message = `Foram apenas encontrados ${this.count.found.length} de ${this.count.total} utilizadores.`
      }
      if (this.count.duplicated.length > 0) {
        message = `Existe(m) ${this.count.duplicated.length} utilizador(es) com certificados duplicados.`
      }

      if (message) {
        this.$buefy.dialog.confirm({
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
          title: `Guardar certificados`,
          message: message + ' Tem a certeza que deseja continuar?',
          onConfirm: async () => {
            await this.saveEnrollments()
            this.close(false)
          }
        })
      } else {
        await this.saveEnrollments()
        this.close(false)
      }
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(file_key) {
      console.log('remove file', file_key)
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que deseja eliminar o ficheiro?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        onConfirm: async () => {
          this.files = this.files.filter(el => el.key != file_key)
        }
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.files, f, { filename: updatedFile.title, key: updatedFile.key })
    },

    uploaded(files) {
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            if (!this.files) this.$set(this.files, [])
            this.files.push({
              key: file.response.data.file.key,
              filename: file.response.data.file.filename
            })
          }
        }
      }
    }
  }
}
</script>
