var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-panel',{attrs:{"title":"Editar metadados","loading":_vm.savingData,"after-loading-checked":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('fw-button',{attrs:{"type":"primary","loading":_vm.savingData,"disabled":_vm.savingData},nativeOn:{"click":function($event){return _vm.saveEdition.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))])]},proxy:true}])},[_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('title.label')))]),(_vm.canEdit)?_c('div',[_c('TextInput',{class:{
          error: _vm.$v.tmpEdition.title.$error
        },attrs:{"minlength":1,"maxlength":250,"placeholder":_vm.$t('title.placeholder')},model:{value:(_vm.tmpEdition.title),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "title", $$v)},expression:"tmpEdition.title"}}),(_vm.$v.tmpEdition.title.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.tmpEdition.title.required)?_c('span',[_vm._v(_vm._s(_vm.$t('title.required')))]):_c('span',[_vm._v(_vm._s(_vm.$t('title.invalid')))])]):_vm._e()],1):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.tmpEdition.title))])],1),_c('div',{staticClass:"mb-2 flex gap-4 items-center w-full"},[_c('div',{staticClass:"w-1/2"},[_c('fw-label',[_vm._v("Data de início")]),(_vm.canEdit)?_c('div',[_c('b-datepicker',{attrs:{"locale":"pt-PT","icon":"calendar-alt","placeholder":"Data de início","trap-focus":""},model:{value:(_vm.tmpEdition.start_date),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "start_date", $$v)},expression:"tmpEdition.start_date"}}),(_vm.$v.tmpEdition.start_date.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.tmpEdition.start_date.required)?_c('span',[_vm._v("Insira a data de início")]):_c('span',[_vm._v("Data de início inválida")])]):_vm._e()],1):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.tmpEdition.start_date))])],1),_c('div',{staticClass:"w-1/2"},[_c('fw-label',[_vm._v("Data de fim")]),(_vm.canEdit)?_c('div',[_c('b-datepicker',{attrs:{"locale":"pt-PT","icon":"calendar-alt","placeholder":"Data de fim","trap-focus":""},model:{value:(_vm.tmpEdition.end_date),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "end_date", $$v)},expression:"tmpEdition.end_date"}}),(_vm.$v.tmpEdition.end_date.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Data de fim tem que ser superior a data de início ")]):_vm._e()],1):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.tmpEdition.end_date))])],1)]),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Website")]),(_vm.canEdit)?_c('div',[_c('TextInput',{class:{
          error: _vm.$v.tmpEdition.website.$error
        },attrs:{"minlength":1,"maxlength":250,"placeholder":_vm.$t('title.placeholder')},model:{value:(_vm.tmpEdition.website),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "website", $$v)},expression:"tmpEdition.website"}}),(_vm.$v.tmpEdition.website.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" URL inválido ")]):_vm._e()],1):_c('fw-panel-info',{attrs:{"clean":""}},[_vm._v(_vm._s(_vm.tmpEdition.website))])],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Descrição")]),_c('HtmlEditor',{class:{
        error: _vm.$v.tmpEdition.description.$error
      },attrs:{"editor-class":"min-h-40"},model:{value:(_vm.tmpEdition.description),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "description", $$v)},expression:"tmpEdition.description"}}),(_vm.$v.tmpEdition.description.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Descrição inválida ")]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Objectivos")]),_c('HtmlEditor',{class:{
        error: _vm.$v.tmpEdition.goals.$error
      },attrs:{"editor-class":"min-h-40"},model:{value:(_vm.tmpEdition.goals),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "goals", $$v)},expression:"tmpEdition.goals"}}),(_vm.$v.tmpEdition.goals.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Objectivos inválida ")]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Público-alvo")]),_c('TextInput',{class:{
        error: _vm.$v.tmpEdition.target.$error
      },attrs:{"minlength":1,"maxlength":250,"placeholder":_vm.$t('title.placeholder')},model:{value:(_vm.tmpEdition.target),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "target", $$v)},expression:"tmpEdition.target"}}),(_vm.$v.tmpEdition.target.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Público-alvo inválido ")]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Pré-requisitos")]),_c('HtmlEditor',{class:{
        error: _vm.$v.tmpEdition.prerequisites.$error
      },attrs:{"editor-class":"min-h-40"},model:{value:(_vm.tmpEdition.prerequisites),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "prerequisites", $$v)},expression:"tmpEdition.prerequisites"}}),(_vm.$v.tmpEdition.prerequisites.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Pré-requisitos inválidos ")]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v("Certificação")]),_c('HtmlEditor',{class:{
        error: _vm.$v.tmpEdition.certification.$error
      },attrs:{"editor-class":"min-h-40"},model:{value:(_vm.tmpEdition.certification),callback:function ($$v) {_vm.$set(_vm.tmpEdition, "certification", $$v)},expression:"tmpEdition.certification"}}),(_vm.$v.tmpEdition.certification.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" Certificação inválida ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }