<template>
  <fw-panel title="Editar metadados" :loading="savingData" after-loading-checked>
    <template #toolbar>
      <fw-button type="primary" :loading="savingData" :disabled="savingData" @click.native="saveEdition">{{
        $t('save')
      }}</fw-button>
    </template>

    <div class="mb-2">
      <fw-label>{{ $t('title.label') }}</fw-label>
      <div v-if="canEdit">
        <TextInput
          v-model="tmpEdition.title"
          :minlength="1"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.tmpEdition.title.$error
          }"
        />
        <fw-tip v-if="$v.tmpEdition.title.$error" error>
          <span v-if="!$v.tmpEdition.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>
      <fw-panel-info v-else clean>{{ tmpEdition.title }}</fw-panel-info>
    </div>

    <div class="mb-2 flex gap-4 items-center w-full">
      <div class="w-1/2">
        <fw-label>Data de início</fw-label>
        <div v-if="canEdit">
          <b-datepicker
            v-model="tmpEdition.start_date"
            locale="pt-PT"
            icon="calendar-alt"
            placeholder="Data de início"
            trap-focus
          >
          </b-datepicker>
          <fw-tip v-if="$v.tmpEdition.start_date.$error" error>
            <span v-if="!$v.tmpEdition.start_date.required">Insira a data de início</span>
            <span v-else>Data de início inválida</span>
          </fw-tip>
        </div>
        <fw-panel-info v-else clean>{{ tmpEdition.start_date }}</fw-panel-info>
      </div>
      <div class="w-1/2">
        <fw-label>Data de fim</fw-label>
        <div v-if="canEdit">
          <b-datepicker
            v-model="tmpEdition.end_date"
            locale="pt-PT"
            icon="calendar-alt"
            placeholder="Data de fim"
            trap-focus
          >
          </b-datepicker>
          <fw-tip v-if="$v.tmpEdition.end_date.$error" error>
            Data de fim tem que ser superior a data de início
          </fw-tip>
        </div>
        <fw-panel-info v-else clean>{{ tmpEdition.end_date }}</fw-panel-info>
      </div>
    </div>

    <div class="mb-2">
      <fw-label>Website</fw-label>

      <div v-if="canEdit">
        <TextInput
          v-model="tmpEdition.website"
          :minlength="1"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.tmpEdition.website.$error
          }"
        />
        <fw-tip v-if="$v.tmpEdition.website.$error" error>
          URL inválido
        </fw-tip>
      </div>
      <fw-panel-info v-else clean>{{ tmpEdition.website }}</fw-panel-info>
    </div>

    <div class="mb-2">
      <fw-label>Descrição</fw-label>
      <HtmlEditor
        v-model="tmpEdition.description"
        editor-class="min-h-40"
        :class="{
          error: $v.tmpEdition.description.$error
        }"
      >
      </HtmlEditor>

      <fw-tip v-if="$v.tmpEdition.description.$error" error>
        Descrição inválida
      </fw-tip>
    </div>

    <div class="mb-2">
      <fw-label>Objectivos</fw-label>
      <HtmlEditor
        v-model="tmpEdition.goals"
        editor-class="min-h-40"
        :class="{
          error: $v.tmpEdition.goals.$error
        }"
      >
      </HtmlEditor>

      <fw-tip v-if="$v.tmpEdition.goals.$error" error>
        Objectivos inválida
      </fw-tip>
    </div>

    <div class="mb-2">
      <fw-label>Público-alvo</fw-label>

      <TextInput
        v-model="tmpEdition.target"
        :minlength="1"
        :maxlength="250"
        :placeholder="$t('title.placeholder')"
        :class="{
          error: $v.tmpEdition.target.$error
        }"
      />

      <fw-tip v-if="$v.tmpEdition.target.$error" error>
        Público-alvo inválido
      </fw-tip>
    </div>

    <div class="mb-2">
      <fw-label>Pré-requisitos</fw-label>
      <HtmlEditor
        v-model="tmpEdition.prerequisites"
        editor-class="min-h-40"
        :class="{
          error: $v.tmpEdition.prerequisites.$error
        }"
      >
      </HtmlEditor>

      <fw-tip v-if="$v.tmpEdition.prerequisites.$error" error>
        Pré-requisitos inválidos
      </fw-tip>
    </div>

    <div class="mb-2">
      <fw-label>Certificação</fw-label>
      <HtmlEditor
        v-model="tmpEdition.certification"
        editor-class="min-h-40"
        :class="{
          error: $v.tmpEdition.certification.$error
        }"
      >
      </HtmlEditor>

      <fw-tip v-if="$v.tmpEdition.certification.$error" error>
        Certificação inválida
      </fw-tip>
    </div>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
import { required, maxLength, minLength, url, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    TextInput,
    HtmlEditor
  },

  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    },
    edition: {
      type: Object,
      default: () => {
        return {
          key: null,
          title: null,
          website: null,
          description: null,
          goals: null,
          target: null,
          prerequisites: null,
          certification: null,
          start_date: null,
          end_date: null
        }
      }
    }
  },

  data() {
    return {
      tmpEdition: {}
    }
  },

  watch: {
    edition(newVal) {
      if (newVal) {
        this.setEditionData(newVal)
      }
    }
  },

  created() {
    this.setEditionData(this.edition)
  },

  validations() {
    return {
      tmpEdition: {
        title: { required, min: minLength(2), max: maxLength(250) },
        website: { url, max: maxLength(250) },
        description: { max: maxLength(65000) },
        goals: { max: maxLength(65000) },
        target: { max: maxLength(65000) },
        prerequisites: { max: maxLength(65000) },
        certification: { max: maxLength(65000) },
        start_date: {},
        end_date: { min: minValue(this.tmpEdition.start_date) }
      }
    }
  },

  methods: {
    setEditionData(edition) {
      let start_date = null
      if (edition.start_date) {
        start_date = new Date(`${edition.start_date}T00:00:00`)
      }
      let end_date = null
      if (edition.end_date) {
        end_date = new Date(`${edition.end_date}T00:00:00`)
      }

      this.tmpEdition = { ...edition, start_date, end_date }
    },
    formatDate(value) {
      return Dates.buildCore(value)
        .tz('Europe/Lisbon')
        .format('YYYY-MM-DD')
    },

    saveEdition() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      let start_date = null
      if (this.tmpEdition.start_date) {
        start_date = this.formatDate(this.tmpEdition.start_date)
      }
      let end_date = null
      if (this.tmpEdition.end_date) {
        end_date = this.formatDate(this.tmpEdition.end_date)
      }
      this.$emit('save', { ...this.tmpEdition, start_date, end_date })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    }
  },
  "en": {
    "close": "Close",
    "edit": "Edit",
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "save": "Save",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    }
  }
}
</i18n>
